<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1">
          Edit Riwayat Perjanjian Kerja
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Jenis Jabatan</span>
            </v-col>

            <v-col cols="12" md="10">
              <v-select
                v-model="item.jenis_jabatan.id"
                class="mt-2"
                :items="jen_jab"
                item-value="id"
                item-text="nama"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Nama Jabatan</span>
            </v-col>

            <v-col cols="12" md="10">
              <v-text-field
                v-model="item.nama_jabatan"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>TMT Jabatan</span>
            </v-col>

            <v-col cols="12" md="10">
              <v-menu
                ref="pick_tmt"
                v-model="pick_tmt"
                :close-on-content-click="false"
                :return-value.sync="item.tmt_jabatan"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.tmt_jabatan"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="item.tmt_jabatan" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pick_tmt = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_tmt.save(item.tmt_jabatan)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Gaji Pokok</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-text-field
                v-model="item.gaji"
                class="mt-2"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Unit Kerja</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-dialog v-model="menuUnor" max-width="70%">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    flat
                    outlined
                    v-model="selected.name"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-card>
                  <v-form ref="pilihUnor">
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Pilih Unit Kerja
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider class="pa-n5"></v-divider>
                    <v-list style="max-height: 300px" class="overflow-y-auto">
                      <div class="pl-5 pr-5 pt-3">
                        <div>
                          <v-autocomplete
                            :items="refUnor"
                            item-value="id"
                            item-text="nama"
                            outlined
                            dense
                            @change="unorChange"
                            return-object
                          >
                          </v-autocomplete>
                        </div>
                        <div>
                          <v-treeview
                            activatable
                            :items="refUnorsub"
                            item-text="namaUnor"
                            item-children="bawahan"
                            return-object
                            :active.sync="selection"
                            selection-type="independent"
                          ></v-treeview>
                        </div>
                      </div>
                    </v-list>
                    <div class="text-right pb-3">
                      <v-btn text @click="clearUnor()"> Clear </v-btn>
                      <v-btn color="error" text @click="menuUnor = false">
                        Batal
                      </v-btn>
                      <v-btn color="primary" text @click="menuUnor = false">
                        Pilih
                      </v-btn>
                    </div>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Nomor Perjanjian Kerja</span>
            </v-col>

            <v-col cols="12" md="5">
              <v-text-field
                v-model="item.noperker"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="5">
              <v-menu
                ref="pick_noperker_tgl"
                v-model="pick_noperker_tgl"
                :close-on-content-click="false"
                :return-value.sync="item.noperker_tgl"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.noperker_tgl"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="item.noperker_tgl" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="pick_noperker_tgl = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_noperker_tgl.save(item.noperker_tgl)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>MHPK</span>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                v-model="item.mhpk_tahun"
                class="mt-2"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-menu
                ref="pick_mhpk_mulai"
                v-model="pick_mhpk_mulai"
                :close-on-content-click="false"
                :return-value.sync="item.mhpk_mulai"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.mhpk_mulai"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal Mulai"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="item.mhpk_mulai" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pick_mhpk_mulai = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_mhpk_mulai.save(item.mhpk_mulai)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4">
              <v-menu
                ref="pick_mhpk_selesai"
                v-model="pick_mhpk_selesai"
                :close-on-content-click="false"
                :return-value.sync="item.mhpk_selesai"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.mhpk_selesai"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal Selesai"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="item.mhpk_selesai" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="pick_mhpk_selesai = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_mhpk_selesai.save(item.mhpk_selesai)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2" class="mt-3">
              <span>Dokumen Perjanjian Kerja</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input
                class="mt-2"
                outlined
                dense
                flat
                prepend-icon
                accept=".pdf"
                label="Dokumen"
                placeholder="Pilih File"
                append-icon="mdi-paperclip"
                @change="onUpload"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-divider class="mb-5"></v-divider>

          <v-row>
            <v-col cols="12" md="2" class="mt-3">
              <span>SPMT</span>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="item.no_spmt"
                class="mt-2"
                hint="Nomor"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-menu
                ref="pick_spmt"
                v-model="pick_spmt"
                :close-on-content-click="false"
                :return-value.sync="item.tgl_spmt"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.tgl_spmt"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="item.tgl_spmt" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pick_spmt = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_spmt.save(item.tgl_spmt)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="2">
              <v-menu
                ref="pick_tmt_spmt"
                v-model="pick_tmt_spmt"
                :close-on-content-click="false"
                :return-value.sync="item.tmt_spmt"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.tmt_spmt"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="TMT"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="item.tmt_spmt" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pick_tmt_spmt = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_tmt_spmt.save(item.tgl_spmt)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="3">
              <v-file-input
                class="mt-2"
                outlined
                dense
                flat
                prepend-icon
                accept=".pdf"
                label="Dokumen"
                placeholder="Pilih File"
                append-icon="mdi-paperclip"
                @change="onSPMT"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-divider class="mb-5"></v-divider>

          <div class="text-right">
            <v-btn
              color="primary"
              :loading="lbtn"
              :disabled="lbtn"
              @click="simpan()"
            >
              Simpan
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refresh from "@/store/asn/pppk/rwperker/view";
import modal_edit from "@/store/asn/pppk/rwperker/modal_edit";

export default {
  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    modalPDF: false,
    lbtn: false,
    menuUnor: false,

    refUnor: [],
    refUnorsub: [],
    selection: [],

    jen_jab: [
      {
        id: 14,
        nama: "Fungsional Keahlian",
      },
      {
        id: 19,
        nama: "Fungsional Keterampilan",
      },
    ],

    get_nip: "",

    pick_tmt: false,
    pick_spmt: false,
    pick_tmt_spmt: false,
    pick_noperker_tgl: false,
    pick_mhpk_mulai: false,
    pick_mhpk_selesai: false,

    defaultPDF: "",
    namaPDF: "",
    doc: "",
    doc_spmt: "",

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],
  }),

  watch: {
    async ModalEdit() {
      this.refUnor = await getRef.Unor(this.token);
    },
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_edit.state.ModalEdit;
      },

      set(value) {
        modal_edit.commit("toggleModal", value);
      },
    },

    item: {
      get() {
        return modal_edit.state.data;
      },

      set(value) {
        console.log(value);
      },
    },

    selected() {
      if (!this.selection.length) {
        let kosong = {
          id: this.item.unor.id,
          id_simpeg: this.item.unor.id_simpeg,
          name: this.item.unor.nama,
          jabatan: this.item.nama_jabatan,
          induk: {
            id_sapk: this.item.unor.induk.id,
            id_simpeg: this.item.unor.induk.id_simpeg,
            nama: this.item.unor.induk.nama,
          },
        };
        return kosong;
      } else {
        let dataUnorSelect = {
          id: this.selection[0].id,
          id_simpeg: this.selection[0].id_simpeg,
          name: this.selection[0].namaUnor,
          jabatan: this.selection[0].namaJabatan,
          induk: {
            id_sapk: this.selection[0].induk.id_sapk,
            id_simpeg: this.selection[0].induk.id_simpeg,
            nama: this.selection[0].induk.nama,
          },
        };
        return dataUnorSelect;
      }
    },
  },

  mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    onUpload(value) {
      this.doc = value;
    },

    onSPMT(value) {
      this.doc_spmt = value;
    },

    async simpan() {
      this.lbtn = true;

      if (this.validate()) {
        const url =
          process.env.VUE_APP_ASN + "pppk/perjanjiankerja/nip/" + this.get_nip;

        const data = new FormData();
        data.append("idperker", this.item.id);
        data.append("doc", this.doc);
        data.append("doc_spmt", this.doc_spmt);
        data.append("unor_sapk", this.selected.id);
        data.append(
          "unor_simpeg",
          this.selected.id_simpeg
            ? this.selected.id_simpeg
            : this.selected.induk.id_simpeg
        );
        data.append("ID_jabatan_status", this.item.jenis_jabatan.id);
        data.append("nama_jabatan", this.item.nama_jabatan);
        data.append("tmt_jabatan", this.item.tmt_jabatan);
        data.append("gaji", this.item.gaji);
        data.append("noperker", this.item.noperker);
        data.append("noperker_tgl", this.item.noperker_tgl);
        data.append("mhpk_tahun", this.item.mhpk_tahun);
        data.append("mhpk_mulai", this.item.mhpk_mulai);
        data.append("mhpk_selesai", this.item.mhpk_selesai);
        data.append("no_spmt", this.item.no_spmt);
        data.append("tgl_spmt", this.item.tgl_spmt);
        data.append("tmt_spmt", this.item.tmt_spmt);

        this.http
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            if (response.data.success) {
              refresh.commit("refreshData", true);
              refresh.commit("alert", response.data.message);
              refresh.commit("berhasilAlert", true);
              refresh.commit("gagalAlert", false);
              refresh.commit("success", response.data.success);
            } else {
              refresh.commit("refreshData", true);
              refresh.commit("alert", response.data.message);
              refresh.commit("gagalAlert", true);
              refresh.commit("berhasilAlert", false);
              refresh.commit("success", response.data.success);
            }
            this.lbtn = false;
            this.closeModal();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token);
            } else if (error.response) {
              refresh.commit("refreshData", true);
              refresh.commit("alert", error.response.data.message);
              refresh.commit("gagalAlert", true);
              refresh.commit("berhasilAlert", false);
              refresh.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.lbtn = false;
              this.closeModal();
            } else {
              refresh.commit("refreshData", true);
              refresh.commit("alert", error.response.data.message);
              refresh.commit("gagalAlert", true);
              refresh.commit("berhasilAlert", false);
              refresh.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.lbtn = false;
              this.closeModal();
            }
          });
      } else {
        window.scrollTo(0, 0);
        this.lbtn = false;
      }
    },

    async unorChange(value) {
      const token = await Cookie.get("token");
      const response = await getRef.subUnor(token, value.id);
      this.refUnorsub = response;
    },

    clearUnor() {
      this.$refs.pilihUnor.reset();
    },

    closeModal() {
      this.ModalEdit = false;
    },
  },
};
</script>
